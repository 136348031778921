import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await API.get(endpoint);
    if (response.status === 200) {
      return response?.data?.data;
    } else {
      return response?.data?.data;
    }
  } catch (error) {
    throw error;
  }
};

export const ReportService = {
  callTiles: async () => {
    return callApi("ewallet-tiles");
  },
  callStatement: async (page, itemsPerPage) => {
    return callApi(`get-ewallet-report?page=${page}&perPage=${itemsPerPage}`);
  },
  callPerformanceBonus: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    username
  ) => {
    return callApi(
      `/performance-bonus-report?type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${itemsPerPage}&username=${username}`
    );
  },
  callRankAchieversList: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    username
  ) => {
    return callApi(
      `get-rank-acievers-report?page=${page}&perPage=${itemsPerPage}&type=${selectedCategory}&startDate=${startDate}&endDate=${endDate}&username=${username}`
    );
  },
  callInsuranceAchieversReport: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,
    username
  ) => {
    return callApi(
      `get-insurance-report?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}&username=${username}`
    );
  },
  callPVReport: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate ,
    username,
    exportData=false
  ) => {
    return callApi(
      `get-pv-history?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}&username=${username}&exportData=${exportData}`
    );
  },
  callTopEarnersService: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate
  ) => {
    return callApi(
      `get-top-earners?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}`
    );
  },
  callAwardsAndRewards: async (
    page,
    itemsPerPage,
    selectedCategory,
    startDate,
    endDate,username  ) => {
    return callApi(
      `get-awards-and-rewards?page=${page}&perPage=${itemsPerPage}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}&username=${username}`
    );
  },


exportData: async (
  type,
  selectedCategory,
  startDate,
  endDate,username  ) => {
    // console.log(typeof(type),"typetypetype");
  return callApi(
    `get-export-data?type=${type}&startDate=${startDate}&endDate=${endDate}&direction=desc&type=${selectedCategory}&username=${username}`
  );
},
};