import React, { useState, useEffect } from "react";
import TableMain from "../Common/table/TableMain";
import EwalletTableFilter from "../Common/table/EwalletTableFilter";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ReportsTable = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("statement");
  const [selectStatement, setSelectedStatement] = useState(true);
  const [selectedTransfer, setSelectedTransfer] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(false);
  const [selectedEarnings, setSelectedEarnings] = useState(false);
  const [username, setUsername] = useState("");
  //PV REPORT
  const [pvReport, setPVReport] = useState(false);
  //TOP EARNERS
  const [topEarnings, setTopEarnings] = useState(false);
  //AWARDS AND REWARDS
  const [awardsAndRewards, setAwardsAndRewards] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );
  //------------------------------------------- API -------------------------------------------
  const statement = ApiHook.CallReportEwalletStatement(
    props.currentPage,
    itemsPerPage,
    selectStatement,
    setSelectedStatement
  );
  const performanceBonus = ApiHook.CallReportPerformanceBonus(
    props.currentPage,
    itemsPerPage,
    selectedTransfer,
    setSelectedTransfer,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );
  //RANK ACHIEVERS
  const rankAchieversHistory = ApiHook.CallRankAchieversReport(
    props.currentPage,
    itemsPerPage,
    selectedPurchase,
    setSelectedPurchase,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );
  //Insurance Achievers
  const insuranceList = ApiHook.CallInsuranceAchievers(
    props.currentPage,
    itemsPerPage,
    selectedEarnings,
    setSelectedEarnings,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );

  //PVREPORT
  const pvList = ApiHook.CallPVReport(
    props.currentPage,
    itemsPerPage,
    pvReport,
    setPVReport,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );
  //TOP EARNERS
  const topEarnersList = ApiHook.CallTOPEarners(
    props.currentPage,
    itemsPerPage,
    topEarnings,
    setTopEarnings,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate
  );

  //AWARDS AND REWARDS
  const awardsAndRewardsList = ApiHook.CallAwardsAndRewards(
    props.currentPage,
    itemsPerPage,
    awardsAndRewards,
    setAwardsAndRewards,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );

  const handleTabChange = (tab) => {
    if (tab === "performance_bonus" || tab === "insurance_achievers") {
      setSelectedCategory("");
    }
    setActiveTab(tab);
    props.setCurrentPage(1);
    setApiTab(tab);
    setUsername("");
  };
  console.log("setUsernamesetUsername",username);
  const setApiTab = (tab) => {
    if (tab === "performance_bonus") {
      setSelectedTransfer(true);
    } else if (tab === "rank_achievers") {
      setSelectedPurchase(true);
    } else if (tab === "insurance_achievers") {
      setSelectedEarnings(true);
    } else if (tab === "pv_report") {
      setPVReport(true);
    } else if (tab === "top_earners") {
      setTopEarnings(true);
    } else if (tab === "awards_and_rewards") {
      setAwardsAndRewards(true);
    } else {
      setSelectedStatement(true);
    }
  };
  const headers =
    activeTab !== "insurance_achievers"
      ? [t("description"), t("amount"), t("transaction_date"), t("balance")]
      : activeTab == "performance_bonus"
      ? [t("amount"), t("user"), t("date"), "tds"]
      : [
          t("description"),
          t("total_amount"),
          t("TDS"),
          t("service_charge"),
          t("amount_payable"),
          t("transaction_date"),
        ];
        
  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="ewallet_table_section_cnt_tab_head">
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "statement" ? "active" : ""
            }`}
            onClick={() => handleTabChange("statement")}
          >
            {t("e-wallet-report")}
          </Link>
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "performance_bonus" ? "active" : ""
            }`}
            onClick={() => handleTabChange("performance_bonus")}
          >
            {t("performance_bonus")}
          </Link>

          <Link
            className={`ewallet_tab_btn ${
              activeTab === "rank_achievers" ? "active" : ""
            }`}
            onClick={() => handleTabChange("rank_achievers")}
          >
            {t("rank_achievers")}
          </Link>
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "insurance_achievers" ? "active" : ""
            }`}
            onClick={() => handleTabChange("insurance_achievers")}
          >
            {t("insurance_achievers")}
          </Link>
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "pv_report" ? "active" : ""
            }`}
            onClick={() => handleTabChange("pv_report")}
          >
            {t("pv_report")}
          </Link>
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "top_earners" ? "active" : ""
            }`}
            onClick={() => handleTabChange("top_earners")}
          >
            {t("top_earners")}
          </Link>
          <Link
            className={`ewallet_tab_btn ${
              activeTab === "awards_and_rewards" ? "active" : ""
            }`}
            onClick={() => handleTabChange("awards_and_rewards")}
          >
            {t("awards_and_rewards")}
          </Link>
        </div>
      </div>
      <div className="table-responsive min-hieght-table">
        {activeTab === "statement" && (
          <>
            <EwalletTableFilter
              type={"ewallet"}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              setUsername={setUsername}
              username={username}
              headers={headers}
              activeTab={activeTab}
              users={statement?.data?.users}
              dateRange={dateRange}
              printData={statement?.data?.data}
            />
            <TableMain
              headers={headers}
              data={statement?.data?.data}
              startPage={1}
              currentPage={statement?.data?.currentPage}
              totalPages={statement?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"ewallet"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "performance_bonus" && (
          <>
            <EwalletTableFilter
              type={activeTab}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              setUsername={setUsername}
              username={username}
              headers={[t("amount"), t("user"), t("date"), t("tds")]}
              printData={performanceBonus?.data?.data}
              activeTab={activeTab}
              users={performanceBonus?.data?.users}
              dateRange={dateRange}
            />
            <TableMain
              headers={[t("amount"), t("user"), t("date"), t("tds")]}
              data={performanceBonus?.data?.data}
              startPage={1}
              currentPage={performanceBonus?.data?.currentPage}
              totalPages={performanceBonus?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"performance_bonus"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "rank_achievers" && (
          <>
            <EwalletTableFilter
              type={activeTab}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              setUsername={setUsername}
              username={username}
              headers={[t("user"), t("name"), t("rank"), t("date")]}
              printData={rankAchieversHistory?.data?.data}
              activeTab={activeTab}
              users={rankAchieversHistory?.data?.users}
              dateRange={dateRange}
            />
            <TableMain
              headers={[t("user"), t("name"), t("rank"), t("date")]}
              data={rankAchieversHistory?.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={rankAchieversHistory?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"rank_achievers"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "insurance_achievers" && (
          <>
            <EwalletTableFilter
              type={"insurance_achievers"}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              category={insuranceList.data?.dropdown}
              setUsername={setUsername}
              username={username}
              headers={[
                t("user"),
                t("name"),
                t("Status"),
                t("fromDate"),
                t("toDate"),
              ]}
              printData={insuranceList?.data?.data}
              activeTab={activeTab}
              users={insuranceList?.data?.users}
              dateRange={dateRange}
            />
            <TableMain
              headers={[
                t("user"),
                t("name"),
                t("Status"),
                t("fromDate"),
                t("toDate"),
              ]}
              data={insuranceList?.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={insuranceList?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"insurance_achievers"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "pv_report" && (
          <>
            <EwalletTableFilter
              type={"pv_report"}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              category={insuranceList.data?.dropdown}
              setUsername={setUsername}
              username={username}
              headers={[
                t("user"),
                t("name"),
                t("totalLeftCount"),
                t("totalRightCount"),
                // t("date"),
              ]}
              printData= {pvList?.data?.data}
              users={pvList?.data?.users}
              dateRange={dateRange}
              activeTab={activeTab}
              
            />
            <TableMain
              headers={[
                t("user"),
                t("name"),
                t("totalLeftCount"),
                t("totalRightCount"),
                // t("date"),
              ]}
              
              data={pvList?.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={pvList?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"pv_report"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "top_earners" && (
          <>
            <EwalletTableFilter
              type={activeTab}
              setApiTab={setApiTab}
              username={username}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              category={insuranceList.data?.dropdown}
              headers={[t("user"), t("name"), t("amount")]}
              printData={topEarnersList?.data?.data} 
              activeTab={activeTab}
              users={topEarnersList?.data?.users}
              dateRange={dateRange}
            />
            <TableMain
              headers={[t("user"), t("name"), t("amount")]}
              data={topEarnersList?.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={topEarnersList?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"top_earners"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
        {activeTab === "awards_and_rewards" && (
          <>
            <EwalletTableFilter
              type={activeTab}
              setApiTab={setApiTab}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setDateRange={setDateRange}
              category={insuranceList.data?.dropdown}
              setUsername={setUsername}
              username={username}
              headers={[
                t("amount"),
                t("tds"),
                t("totalAmount"),
                t("dateAdded"),
              ]}
              printData={awardsAndRewardsList?.data?.data}
              activeTab={activeTab}
              users={awardsAndRewardsList?.data?.users}
              dateRange={dateRange}
            />
            <TableMain
              headers={[
                t("amount"),
                t("tds"),
                t("totalAmount"),
                t("dateAdded"),
              ]}
              data={awardsAndRewardsList?.data?.data}
              startPage={1}
              currentPage={props.currentPage}
              totalPages={awardsAndRewardsList?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              type={"awards_and_rewards"}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              activeTab={activeTab}
              setApiTab={setApiTab}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportsTable;
