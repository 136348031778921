import React, { useState, useRef } from "react";

const IdCard = ({ profile }) => {
  console.log({ profile });
  const reportTemplateRef = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleDownload = async () => {
    const content = document.getElementById("id_card_main_div");
    if (content) {
      const printWindow = window.open("", "", "width=800,height=600");

      // Clone all the stylesheets
      const styleSheets = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            // Extract CSS rules from stylesheets
            if (
              styleSheet.href &&
              styleSheet.href == "https://user.fairobuy.com/id_card.css"
            ) {
              const CSS = Array.from(styleSheet.cssRules)
                .map((rule) => rule.cssText)
                .join("\n");
              return CSS;
            }
          } catch (e) {
            console.log(
              "Cannot access cssRules for a stylesheet:",
              styleSheet.href,
              e
            );
            return "";
          }
        })
        .join("\n");

      printWindow.document.write(`
        <html>
          <head>
            <title>Print Content</title>
            <style>${styleSheets}</style>
          </head>
          <body>
            ${content.innerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://user.fairobuy.com/id_card.css"
      />

      <div
        id="id_card_main_div"
        style={{ overflow: "auto", padding: "15px" }}
        ref={reportTemplateRef}
      >
        <div
          className="id-card"
          // style={{ border: "1px solid #000", padding: "10px" }}
        >
          <div className="id-card-left">
            <div className="id-card-user_image">
              <img
                src={profile?.profile?.avatar}
                alt="User"
                crossOrigin="anonymous"
                onLoad={handleImageLoad}
              />
            </div>
            <div className="id-card-user_details">
              <div className="id-card-user_details_div">
                <p data-label="NAME:">{profile?.profile?.fullName}</p>
                <p data-label="DESIGNATION:">
                  {profile?.profile?.rankDetails?.currentRank
                    ? profile?.profile?.rankDetails?.currentRank?.name
                    : "DISTRIBUTOR"}
                </p>
                <p data-label="PHONE NUMBER:">{profile?.profile?.mobile}</p>
              </div>
            </div>
          </div>
          <div className="id-card-right"></div>
        </div>
      </div>
      <button onClick={handleDownload} style={{ marginTop: "20px" }}>
        Download ID Card
      </button>
    </div>
  );
};

export default IdCard;
