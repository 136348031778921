import React, { useState, useRef, useEffect } from "react";
import SubmitButton from "../buttons/SubmitButton";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import { exportToCSV, exportToExcel } from "../../../utils/tableExports";
import { useReactToPrint } from "react-to-print";
import LabelledButton from "../buttons/LabelledButton";
import { PrintView } from "../PrintView";
import { ApiHook } from "../../../hooks/apiHook";

const { RangePicker } = DatePicker;

const EwalletTableFilter = ({
  type,
  setApiTab,
  selectedCategory,
  setSelectedCategory,
  setDateRange,
  category,
  setUsername,
  username,
  headers,
  users,
  dateRange,
  activeTab,
  printData
}) => {
  const tableRef = useRef();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(selectedCategory);
  const [dateKey, setDateKey] = useState(Date.now());
  const [exportFormat, setExportFormat] = useState(null); // Store export format
  const [printReady, setPrintReady] = useState(false); // Control print readiness
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
console.log(username,"setUsernamesetUsername");
  const listOptions = category?.map((item) => ({
    label: t(`${item.label}`),
    value: item.value,
  }));

  // API Hook
  const { data, refetch: fetchExportData } = ApiHook.ExportData(
    activeTab,
    selectedCategory,
    dateRange?.startDate,
    dateRange?.endDate,
    username
  );

  useEffect(() => {
    if (data && exportFormat) {
      
      if (exportFormat === "csv") {
        exportToCSV(data.data, headers, type, t, userSelectedCurrency);
      } else if (exportFormat === "excel") {
        exportToExcel(data.data, headers, type, t, userSelectedCurrency);
      }
      setExportFormat(null); 
    }
  }, [data, exportFormat]);

  const handleExport = (format) => {
    setExportFormat(format); 
    fetchExportData(); // Trigger the API call
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    // onBeforeGetContent: () => {
    //   return new Promise((resolve) => {
    //     if (!data?.data) {
    //       console.log("Fetching data...");
    //       fetchExportData().then(() => resolve());
    //     } else {
    //       resolve();
    //     }
    //   });
    // },
    // onAfterPrint: () => {
    //   console.log("Print process finished.");
    //   setPrintReady(false);
    // },
  });

  useEffect(() => {
    if (printReady) {
      handlePrint();
    }
  }, [printReady]);

  const handleTabChange = (e) => {
    const { id } = e.target;
    if (id === "searchButton") {
      setApiTab(type);
    } else if (id === "resetButton") {
      setSelectedCategory([]);
      setSelectedValue([]);
      setDateRange({ startDate: "", endDate: "" });
      setApiTab(type);
      setUsername(null);
      setDateKey(Date.now());
    }
  };

  const handleSelectChange = (values) => {
    const selectedCategories = values.map((v) => v.value);
    setSelectedCategory(selectedCategories);
    setSelectedValue(values);
  };

  const handleDateRange = (dates) => {
    if (Array.isArray(dates) && dates.length === 2) {
      const [toDate, fromDate] = dates;
      setDateRange({
        startDate: toDate.format("YYYY-MM-DD H:m:s"),
        endDate: fromDate.format("YYYY-MM-DD H:m:s"),
      });
    }
  };

  const handleUsername = (e) => {
    setUsername(e.value);
  };

  return (
    <div className="filter_Section">
      <div className="row export-parent-custome">
        <div className="export-sibling1-custome">
          {type !== "ewallet" &&
            type !== "transfer_history" &&
            type !== "my_earnings" &&
            type !== "top_earners" && (
              <div key={"1"} className="col-xl-2 col-md-3">
                <div className="right_search_div">
                  <label className="la-control">{t("username")}</label> <br />
                  <Select
                    options={users ?? []}
                    value={users?.find((user) => user.value === username) || null}
                    onChange={handleUsername}
                  />
                </div>
              </div>
            )}

          {type !== "top_earners" && type !== "ewallet" && (
            <div key={"2"} className="col-xl-2 col-md-3">
              <div className="right_search_div">
                <label className="la-control">{t("date")}</label> <br />
                <RangePicker
                  key={dateKey}
                  onChange={(dates) => handleDateRange(dates)}
                />
              </div>
            </div>
          )}

          {type === "transfer_history" && (
            <div key={"3"} className="col-xl-2 col-md-3">
              <div className="right_search_div">
                <label className="la-control">{t("category")}</label>
                <Select
                  options={[
                    { label: t("credit"), value: "credit" },
                    { label: t("debit"), value: "debit" },
                  ]}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  isMulti
                />
              </div>
            </div>
          )}

          {type === "my_earnings" && (
            <div key={"4"} className="col-xl-3 col-md-3">
              <div className="right_search_div">
                <label className="la-control">{t("category")}</label>
                <Select
                  options={listOptions}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  isMulti
                />
              </div>
            </div>
          )}

          {type !== "top_earners" && type !== "ewallet" && (
            <div className="col-xl-2 col-md-3 mt-4">
              <div className="right_search_div d-flex gap-1">
                <SubmitButton
                  text={t("search")}
                  className="btn btn-primary"
                  click={handleTabChange}
                  id="searchButton"
                />
                <SubmitButton
                  text={t("reset")}
                  className="btn btn-secondary"
                  click={handleTabChange}
                  id="resetButton"
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4 text-end mob_filter_right export-cutome">
          <LabelledButton
            className="fa fa-file-excel"
            text=" Excel"
            click={() => handleExport("excel")}
          />
          <LabelledButton
            className="fa fa-file-text"
            text=" CSV"
            click={() => handleExport("csv")}
          />
         <LabelledButton
            className="fa fa-print"
            text=" Print"
            click={() => setPrintReady(true)} // Set ready for print
          />
        </div>
        <div style={{ display: "none", padding: "10px" }}>
          <PrintView ref={tableRef} data={printData} headers={headers} type={type} />
        </div>
      </div>
    </div>
  );
};

export default EwalletTableFilter;
