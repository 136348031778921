import React, { useState } from "react";

const AddLeadForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    skype_id: "",
    mobile_no: "",
    country: "",
    interest_status: "Very Interested",
    next_followup_date: "",
    lead_status: "Ongoing",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      first_name: formData.first_name,
      last_name: formData.last_name,
      email_id: formData.email_id,
      skype_id: formData.skype_id,
      mobile_no: formData.mobile_no,
      country: formData.country,
      interest_status: formData.interest_status,
      next_followup_date: formData.next_followup_date,
      lead_status: formData.lead_status,
      description: formData.description,
    });
  };

  return (
    <>
      <div className="page_head_top">ADD LEAD</div>
      <div className="frm-content">
        <div className="p-3 container-fluid">
          <div className="crm_panel__h6izZ undefined">
            <div className="crm_panel_body__31wq1">
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">First Name</label>
                      <input
                        name="first_name"
                        placeholder="First Name"
                        type="text"
                        className="form-control"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Last Name</label>
                      <input
                        name="last_name"
                        placeholder="Last Name"
                        type="text"
                        className="form-control"
                        value={formData.last_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        name="email_id"
                        placeholder="Email ID"
                        type="text"
                        className="form-control"
                        value={formData.email_id}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">Skype</label>
                      <input
                        name="skype_id"
                        placeholder="Skype ID"
                        type="text"
                        className="form-control"
                        value={formData.skype_id}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Mobile No</label>
                      <input
                        name="mobile_no"
                        placeholder="Mobile No"
                        type="text"
                        className="form-control"
                        value={formData.mobile_no}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Country</label>
                      <select
                        name="country"
                        className="form-control"
                        value={formData.country}
                        onChange={handleChange}
                      >
                        <option value="country">Country</option>
                        {/* Add other country options */}
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    <div className="form-group">
                      <label className="form-label">Level Of Interest</label>
                      <select
                        name="interest_status"
                        className="form-control"
                        value={formData.interest_status}
                        onChange={handleChange}
                      >
                        <option value="Very Interested">Very Interested</option>
                        <option value="Interested">Interested</option>
                        <option value="Not That Interested">
                          Not That Interested
                        </option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Next Followup Date</label>
                      <input
                        name="next_followup_date"
                        placeholder="Next Followup Date"
                        type="text"
                        className="form-control"
                        value={formData.next_followup_date}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Lead Status</label>
                      <select
                        name="lead_status"
                        className="form-control"
                        value={formData.lead_status}
                        onChange={handleChange}
                      >
                        <option value="Ongoing">Ongoing</option>
                        <option value="Rejected">Rejected</option>
                        <option value="Accepted">Accepted</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <input
                        name="description"
                        placeholder="Description"
                        type="text"
                        className="form-control"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="Common_customBtn__2_PSp Common_primary__2pdY1 undefined btn btn-primary"
                >
                  Add Lead
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeadForm;
