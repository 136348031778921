import React from "react";
import { useTranslation } from "react-i18next";

const DeliveryMode = ({ isEditModeEnabled, value }) => {
  const { t } = useTranslation();
  const deliveryDetails = [
    {
      id: 3,
      type: "text",
      label: "Delivery Mode",
    },
    {
      id: 3,
      type: "text",
      label: "Delivery Code",
    },
    {
      id: 3,
      type: "text",
      label: "Delivery Shopee",
    },
  ];
  return (
    <div id="fifthTab" className="tabcontent">
      <h3>Delivery Mode</h3>
      <div className="tabcontent_form_section">
        {deliveryDetails.map((input, index) => (
          <div className="mb-3 row tabBlockClass" key={index}>
            <label
              htmlFor={input.id}
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {input.label}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                disabled={true}
                value={
                  input.label == "Delivery Mode"
                    ? t(value.deliveryMode)
                    : input.label == "Delivery Code"
                    ? value.deliveryCode
                    : value.deliveryShoppee
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveryMode;
