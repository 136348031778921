import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import Loader from "react-js-loader";

const Return = () => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // This will subscribe to the changes of the "returnFor" field
  const formData = watch();
  const returnFor = watch("returnFor");
  const [returnQuantities, setReturnQuantities] = useState({});
  const [addedItems, setAddedItems] = useState([]);

  const handleQuantityChange = (id, availableQuantity, e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (
      !isNaN(newQuantity) &&
      newQuantity <= availableQuantity &&
      newQuantity >= 0
    ) {
      setReturnQuantities((prev) => ({
        ...prev,
        [id]: newQuantity,
      }));
    } else {
      setReturnQuantities((prev) => ({
        ...prev,
        [id]: "",
      }));
    }
  };

  const handleAdd = (item) => {
    console.log("====== added ===== ");
    const returnQuantity = returnQuantities[item.orderProductId] || 0;
    if (returnQuantity != 0) {
      console.log("====== added ===== ");
      if (returnQuantity <= item.quantity) {
        console.log("====== added =====1 ");
        const updatedItems = addedItems.filter(
          (x) => x.id !== item.orderProductId
        );
        setAddedItems([
          ...updatedItems,
          {
            id: item.orderProductId,
            returnQuantity: returnQuantity,
            name: item.name,
          },
        ]);
      } else {
        toast.error("Return quantity exceeds available quantity!");
      }
    }
  };

  const checkUsernameField = ApiHook.CallDownlineCheck();

  const checkOrder = ApiHook.CallOrderCheck();

  const createReturnOrder = ApiHook.CallCreateOrderReturn();

  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    checkUsernameField.mutate({ field: "username", value: value });
    setAddedItems([]);
  };

  const handleOrderNumberCheck = async (item) => {
    checkOrder.mutate({ ...formData, ["orderNumber"]: item.value });
    setAddedItems([]);
  };

  const handleDelete = (id) => {
    setAddedItems(addedItems.filter((item) => item.id !== id));
  };

  const handleSubmit = () => {
    console.log("handle submit");
    console.log(addedItems);
    createReturnOrder.mutate({ ...formData, items: addedItems });
  };

  if (createReturnOrder.isLoading) {
    return (
      <>
        <Loader type="bubble-scale" bgColor={"black"} size={30} />
      </>
    );
  }
  return (
    <>
      <div className="page_head_top">{t("return")}</div>
      <div className="row row_top justify-content-center form-business">
        <div className="regsiter_step_1_view_left_sec_head">
          {t("returnFor")}
        </div>
        <div className="regsiter_second_step_bx reg_select_pos">
          <div className="regsiter_step_1_view_left_btn_row_return mt-0">
            <label
              className={`regsiter_step_1_view_left_btn ${
                returnFor === "self" ? "active" : ""
              }`}
            >
              <input
                type="radio"
                name="returnFor"
                value="self"
                {...register("returnFor", { required: true })}
                onClick={() => {
                  setReturnQuantities([]);
                  setAddedItems([]);
                  setValue("orderNumber", "");
                  checkOrder.reset();
                }}
              />
              {t("self")}
            </label>
            <label
              className={`regsiter_step_1_view_left_btn ${
                returnFor === "downlineMember" ? "active" : ""
              }`}
            >
              <input
                type="radio"
                name="returnFor"
                value="downlineMember"
                {...register("returnFor", { required: true })}
                onClick={() => {
                  setReturnQuantities([]);
                  setAddedItems([]);
                  setValue("orderNumber", "");
                  checkOrder.reset();
                }}
              />
              {t("forDownlineMember")}
            </label>
          </div>
          {errors.returnFor && (
            <span className="error-message-validator">
              {t("this_field_is_required")}
            </span>
          )}

          {/* Conditionally render the input field for downline member details */}
          {returnFor === "downlineMember" && (
            <div className="col-md-12">
              <div className="regsiter_form_box return_margin">
                <label htmlFor={"downlineMemberUserName"}>
                  {t("downlineMemberUserName")}{" "}
                  <span className="text-danger">٭</span>
                </label>
                <>
                  <input
                    name="downlineMemberUsername"
                    type="text"
                    className={`form-control
                    }`}
                    placeholder="Downline Member Username"
                    {...register("downlineMemberUsername", { required: true })}
                    onChangeCapture={async (e) => {
                      await handleUsernameCheck(e.target);
                    }}
                    // onBlur={async () => await trigger(item.code)}
                  />
                  {!checkUsernameField.data?.status && (
                    <span className="error-message-validator">
                      Invalid Details
                    </span>
                  )}
                </>

                {errors["downlineMemberUsername"] && (
                  <span className="error-message-validator">
                    {errors["downlineMemberUsername"].message}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="col-md-12 m-2">
            <div className="regsiter_form_box return_margin">
              <label htmlFor={"orderNumber"}>
                {t("orderNumber")} <span className="text-danger">٭</span>
              </label>
              <>
                <input
                  name="orderNumber"
                  type="text"
                  className={`form-control
                    }`}
                  placeholder="Order Number"
                  {...register("orderNumber", { required: true })}
                  onChangeCapture={async (e) => {
                    await handleOrderNumberCheck(e.target);
                  }}
                  // onBlur={async () => await trigger(item.code)}
                />
                {/* {!checkUsernameField.data?.status && (
                  <span className="error-message-validator">
                    Invalid Details
                  </span>
                )} */}
              </>

              {errors["orderNumber"] &&
                // checkUsernameField.data?.data?.field === "username" &&
                checkUsernameField.data?.data?.code === 1117 && (
                  <span className="error-message-validator">
                    {errors["downlineUsername"].message}
                  </span>
                )}
            </div>
          </div>
          <div className="regsiter_second_step_bx reg_select_pos">
            <div className="row">
              <div className="col-md-12">
                {checkOrder.data && !checkOrder.data.status ? (
                  <span>{checkOrder.data.data.description}</span>
                ) : (
                  checkOrder.data &&
                  checkOrder.data.data && (
                    <div className="row">
                      <div className="ewallet_table_section">
                        <div className="ewallet_table_section_cnt">
                          <div className="table-responsive min-hieght-table">
                            <div className="table-container">
                              <table
                                className="striped"
                                style={{ width: "100%" }}
                              >
                                <thead>
                                  <tr>
                                    <th style={{ paddingRight: "5px" }}>ID</th>
                                    <th style={{ paddingRight: "5px" }}>
                                      Name
                                    </th>
                                    <th style={{ paddingRight: "5px" }}>
                                      Quantity
                                    </th>
                                    <th style={{ paddingRight: "5px" }}>
                                      Quantity To Return
                                    </th>
                                    <th style={{ paddingRight: "5px" }}>Add</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {checkOrder?.data?.data?.data?.data?.map(
                                    (item, index) => (
                                      <tr key={index}>
                                        <td>{item.orderProductId}</td>
                                        <td>{item.name}</td>
                                        <td>{item.quantity}</td>
                                        <td>
                                          <input
                                            type="number"
                                            value={
                                              returnQuantities[
                                                item.orderProductId
                                              ] || ""
                                            }
                                            onChange={(e) =>
                                              handleQuantityChange(
                                                item.orderProductId,
                                                item.quantity,
                                                e
                                              )
                                            }
                                            min="0"
                                            max={item.quantity}
                                          />
                                        </td>
                                        <td>
                                          <button
                                            onClick={() => handleAdd(item)}
                                            className="return_btn"
                                          >
                                            Add
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="pagination">
                    {[...Array(totalPages).keys()].map((number) => (
                      <button
                        key={number}
                        onClick={() => paginate(number + 1)}
                      >
                        {number + 1}
                      </button>
                    ))}
                  </div> */}
                    </div>
                  )
                )}
                {addedItems.length > 0 && (
                  <div className="table-responsive min-height-table">
                    <table className="striped" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Return Quantity</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addedItems.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.returnQuantity}</td>
                            <td>
                              <button
                                onClick={() => handleDelete(item.id)}
                                className="return_btn"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="col-12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ marginTop: "20px", width: "100px" }}>
                        <button
                          onClick={handleSubmit}
                          className="submit_btn"
                          style={{}}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Return;
