import { ReportService } from "../../services/report/reportServices";

export const Tiles = async () => {
  try {
    const response = await ReportService.callTiles();
    return response;
  } catch (error) {
    return error.message;
  }
};

export const ReportStatement = async (page, itemsPerPage) => {
  try {
    const response = await ReportService.callStatement(page, itemsPerPage);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const PerformanceBonus = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  username
) => {
  try {
    const response = await ReportService.callPerformanceBonus(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      username
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const RankAchievers = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  username
) => {
  try {
    const response = await ReportService.callRankAchieversList(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      username
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const InsuranceAchievers = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  username
) => {
  try {
    const response = await ReportService.callInsuranceAchieversReport(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      username
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const PVReport = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  username,
  exportData = false
) => {
  try {
    const response = await ReportService.callPVReport(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      username,
      exportData
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const TopEarners = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate
) => {
  try {
    const response = await ReportService.callTopEarnersService(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const AwardsAndRewards = async (
  page,
  itemsPerPage,
  selectedCategory,
  startDate,
  endDate,
  username
) => {
  try {
    const response = await ReportService.callAwardsAndRewards(
      page,
      itemsPerPage,
      selectedCategory,
      startDate,
      endDate,
      username
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const ExportData = async (
  type,
  selectedCategory,
  startDate,
  endDate,
  username
) => {
  try {
    const response = await ReportService.exportData(
      type,
      selectedCategory,
      startDate,
      endDate,
      username
    );
    return response;
  } catch (error) {
    return error.message;
  }
};
