import React from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import Loader from "react-js-loader";
import BecomeHomeShoppee from "../../components/HomeShoppee/BecomeHomeShoppee";

const HomeShoppee = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user?.loginResponse?.user);
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  //------------------------------------------ API ----------------------------------------

  const data = ApiHook.CallGetHomeShoppeeDetails();
  if (data?.isLoading || data?.isError) {
    <>
      <div className="page_head_top">{t("becomeAHomeShoppee")}</div>
      <Loader
        type="bubble-scale"
        bgColor={"#954cea"}
        color={"#ffffff"}
        size={25}
      />
    </>;
  } else {
    return (
      <>
        <div className="page_head_top">{t("becomeAHomeShoppee")}</div>
        {!data?.data?.status && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <h1>{data?.data?.data?.description}</h1>
          </div>
        )}
        {data?.data?.status && (
          <BecomeHomeShoppee
            data={data}
            userData={userData ? JSON.parse(userData) : null}
            currency={userSelectedCurrency}
          />
        )}
      </>
    );
  }
};

export default HomeShoppee;
