// //LOCAL
// const BASE_URL = "http://192.168.9.47:5010/node_api/";
// const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

// DEMO
// const BASE_URL = "http://162.19.146.135:1418/node_api/";
// const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
// const PAYPAL_CLIENT_ID =
//     "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

// LIVE
const BASE_URL = "https://admin.fairobuy.com/node_api/";
// const BASE_URL = "http://65.108.214.231:5000/node_api/"
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const PAYPAL_CLIENT_ID =
    "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };