import * as XLSX from "xlsx";
import Papa from "papaparse";

export const formatData = async (data, type, t, userSelectedCurrency) => {
    let formattedData = [];
    if (type === "ewallet") {
        formattedData = data.map(row => {
            let description = '';

            if (row.ewalletType === "commission") {
                description = `${t(row.amountType)} ${row.fromUser ? t("from") + " " + row.fromUser.toUpperCase() : ""}`;
            } else if (row.ewalletType === "fund_transfer") {
                description = `${t(row.amountType)} ${row.type === "credit" ? row.fromUser ?? "" : row.toUser ?? ""} ${row.transactionFee !== 0 ? ` (${t("transactionFee")} : ${userSelectedCurrency?.symbolLeft} ${row.transactionFee})` : ""}`;
            } else if (row.ewalletType === "pin_purchase") {
                description = `${t(row.amountType)}`;
            } else if (row.ewalletType === "ewallet_payment") {
                description = `${t(row.amountType)} ${t("using_ewallet")}`;
            } else if (row.ewalletType === "payout") {
                description = `${t(row.amountType)}`;
            }

            return {
                Description: description,
                amount: row.amount,
                date: row.dateAdded,
                balance: row.balance
            };
        });
        return formattedData;
    }else{
        return data ;
    }
   
};

export const exportToExcel = async (data, headers, type, t, userSelectedCurrency) => {
    const formattedData = await formatData(data, type, t, userSelectedCurrency);
    const wsData = formattedData.map(obj => Object.values(obj));
    const ws = XLSX.utils.aoa_to_sheet([headers, ...wsData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    XLSX.writeFile(wb, `${type}.xlsx`);
};

export const exportToCSV = async (data, headers, type, t, userSelectedCurrency) => {
    console.log("1111111111111111");
    const formattedData = await formatData(data, type, t, userSelectedCurrency);
    const csvData = Papa.unparse([headers, ...formattedData.map(obj => Object.values(obj))]);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${type}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};