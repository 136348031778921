import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReportsTable from "../../components/Reports/ReportsTable";

const ReportLayout = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  return (
    <>
      <div className="page_head_top">{t("report")}</div>
      <ReportsTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        currency={userSelectedCurrency}
      />
    </>
  );
};

export default ReportLayout;
