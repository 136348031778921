import React, { useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";

const NomineeDetails = ({ isEditModeEnabled, value }) => {
  const [details, setDetails] = useState({
    nomineeName: value?.nomineeName || "",
    nomineeRelationship: value?.nomineeRelationship || "",
  });
  const nomineeDetailMutation = ApiHook.CallUpdateNomineeDetails();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdateClick = () => {
    // Logic to handle the update, e.g., make an API call to save the details
    nomineeDetailMutation.mutate(details, {
      onSuccess: (res) => {
        console.log(res.status, "response from nominee details updation");
        if (res?.status) {
          toast.success(res?.data);
        } else {
          toast.error(
            res?.description ? res?.description : "Some Error Occured"
          );
        }
      },
    });
  };

  const deliveryDetails = [
    {
      id: "nomineeName",
      type: "text",
      label: "Name",
    },
    {
      id: "nomineeRelationship",
      type: "text",
      label: "Relationship",
    },
  ];

  return (
    <div id="fifthTab" className="tabcontent">
      <h3>Nominee Details</h3>
      <div className="tabcontent_form_section">
        {deliveryDetails.map((input, index) => (
          <div className="mb-3 row tabBlockClass" key={index}>
            <label
              htmlFor={input.id}
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {input.label}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                type={input.type}
                id={input.id}
                name={input.id}
                value={details[input.id]}
                onChange={handleInputChange}
                disabled={!isEditModeEnabled}
              />
            </div>
          </div>
        ))}
        {isEditModeEnabled && (
          <div className="row">
            <div className="col-md-9 offset-sm-3">
              <button onClick={handleUpdateClick} className="btn btn-primary">
                Update Details
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NomineeDetails;
