import React, { useEffect, useRef, useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";


const BecomeHomeShoppee = ({ data, userData, currency }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [submitButtonActive, setSubmitButtonActive] = useState(false);
  const progressBarRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);

  const user = useSelector((state) => state?.user?.loginResponse?.user);
  const username = user ? JSON.parse(user) : null;
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  // ----------------------------------- API --------------------------------------

  const renewsubscriptionMutation = ApiHook.CallBecomeHomeShoppee();

  useEffect(() => {
    const strokeDashOffsetValue =
      100 - (data?.productValidity?.packageValidityPercentage ?? 100);
    progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  }, [data?.productValidity?.packageValidityPercentage]);

  const handleBack = () => {
    navigate("/dashboard");
  };

  const handleSubmit = (paymentId) => {
    // renewsubscriptionMutation.mutate(formValues, {
    //   onSuccess: (res) => {
    //     if (res.status) {
    //       toast.success(t(res?.data));
    //       navigate("/profile");
    //     } else {
    //       toast.error(t(res?.description));
    //     }
    //   },
    // });
    setShowWarning(true);
  };

  const purchaseHomeShoppee = () => {
    renewsubscriptionMutation.mutate(
      {},
      {
        onSuccess: (data) => {
          // Handle success here
          if (data.status) {
            toast.success(data?.data);
            setShowWarning(false);
          } else {
            toast.error(data?.description);
          }
        },
        onError: (error) => {
          // Handle error here
          console.error("Mutation error:", error);
          // You can perform any actions you need on error
        },
      }
    );
  };

  return (
    <>
      <div className="package_upgrade_cnt_sec">
        <div className="renew-plan">
          <div className="row justify-content-center">
            <div className="renewcenterBox">
              <div className="profileBgBox">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-12 borderPofileStyle">
                    <div className="rightSide_top_user_dropdown">
                      <div className="rightSide_top_user_dropdown_avatar_sec">
                        <div className="rightSide_top_user_dropdown_avatar_extra_padding avatarProfileStyle">
                          <img
                            src={`${
                              data?.image ?? "/images/profile-image-new.png"
                            }`}
                            alt=""
                          />
                          <svg
                            className="profile_progress avatarProfileProgress"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-1 -1 34 34"
                          >
                            <circle
                              cx="16"
                              cy="16"
                              r="15.9155"
                              className="progress-bar__background"
                            />
                            <circle
                              ref={progressBarRef}
                              cx="16"
                              cy="16"
                              r="15.9155"
                              className="progress-bar__progress js-progress-bar"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="profileAvatarnameSec">
                        <h4>{userData?.fullName}</h4>
                        <div className="kycDetailProfile">
                          <div className="kycDetailProfile_cnt">
                            <h6>{t("price")} :</h6>
                            <h6>{`${currency?.symbolLeft} ${CurrencyConverter(
                              data?.data?.data?.data?.amount,
                              conversionFactor
                            )}`}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 border-prf-left">
                    <div className="row">
                      <h6>{data?.data?.data?.data?.content}</h6>
                    </div>
                    <div className="renewalBackBtn">
                      <button
                        className="plan-choose-back renew_btn mt-2"
                        onClick={handleBack}
                      >
                        {t("back")}
                      </button>
                      <button
                        type="submit"
                        className="plan-choose renew_btn mt-2"
                        disabled={submitButtonActive}
                        onClick={handleSubmit}
                      >
                        {t("finish")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showWarning} onHide={() => setShowWarning(false)}>
        <Modal.Header>
          <Modal.Title>{t("becomeAHomeShoppee")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to become a home shoppee ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={purchaseHomeShoppee}>
            {t("yes")}
          </Button>
          <Button variant="secondary" onClick={() => setShowWarning(false)}>
            {t("no")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BecomeHomeShoppee;
